<template>
	<div class="error-page">
		<div class="logo"></div>
		<div class="ep-con">
			<div class="text">ERROR</div>
			<div class="code">404</div>
			<div class="desc">找不到相关页面</div>
			<el-button type="primary" @click="toHome">首页</el-button>
		</div>
	</div>
</template>
<script>
import { defineComponent, toRefs, reactive } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
	setup() {
		const router = useRouter()
		const methods = reactive({
			toHome() {
				router.push({
					path: '/',
				})
			},
		})
		return {
			...toRefs(methods),
		}
	},
})
</script>
<style lang="less" scoped>
.error-page {
	position: relative;
	width: 100%;
	height: 100%;
	background: url('https://websitedn.yiautos.com/nrp4/main/bg_error.png') no-repeat center center;
	.ep-con {
		width: 50%;
		height: 400px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	.logo {
		position: absolute;
		top: 40px;
		left: 50px;
		width: 160px;
		height: 60px;
		background: url('https://websitedn.yiautos.com/nrp4/main/logo_160_60.png') no-repeat center
			center;
	}
	.text {
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		font-size: 40px;
		color: #333333;
		letter-spacing: 0;
		text-align: center;
		line-height: 40px;
	}
	.code {
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		font-size: 220px;
		color: #409eff;
		letter-spacing: 0;
		text-align: center;
		line-height: 220px;
		padding: 10px 0;
	}
	.desc {
		font-family: PingFangSC-Regular;
		font-weight: 400;
		font-size: 26px;
		color: #b0b0b0;
		letter-spacing: 0;
		text-align: center;
		line-height: 26px;
	}
	.yi-button {
		width: 160px;
		height: 46px;
		margin: 40px auto 0;
		display: block;
	}
}
</style>
